import { NewbuildingKpnBannerContext, KpnBanner } from '@cian/frontend-newbuilding-kpn-banners';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { EBannerType } from 'shared/constants/banner';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { selectNewbuildingRentTrapGroup } from 'shared/selectors/abExperiments';
import { getUserPhone } from 'shared/selectors/callbackRightNow';
import { getGaLabel } from 'shared/selectors/newObject';
import { regionIdSelector } from 'shared/selectors/offerData/geo/regionIdSelector';
import { selectNewbuildingRentTrapUrl } from 'shared/selectors/selectNewbuildingRentTrapUrl';
import { getBannerType } from 'shared/utils/newbuildingRentBanner/getBannerType';
import { getPrivacyPolicyUrl } from 'shared/utils/newbuildingRentBanner/getPrivacyPolicyUrl';

interface INewbuildingRentBannerContainerProps {
  bannerType: EBannerType.RentKpnCallback | EBannerType.RentNBListing;
}

export const NewbuildingRentBannerContainer = ({ bannerType }: INewbuildingRentBannerContainerProps) => {
  const context = useApplicationContext();

  const regionId = useSelector(regionIdSelector);
  const experimentGroup = useSelector(selectNewbuildingRentTrapGroup);
  const rentTrapUrl = useSelector(selectNewbuildingRentTrapUrl);
  const phone = useSelector(getUserPhone);
  const gaLabel = useSelector(getGaLabel);

  const personalAgreementLink = React.useMemo(() => {
    return getPrivacyPolicyUrl(context.config);
  }, [context]);

  const defaultPhoneNumber = React.useMemo(() => phone && phone.substring(1), [phone]);

  const banner = React.useMemo(() => {
    return getBannerType({ bannerType, experimentGroup });
  }, [bannerType, experimentGroup]);

  return (
    <div data-testid="NewbuildingRentBannerContainer">
      <NewbuildingKpnBannerContext.Provider value={context}>
        <KpnBanner
          placeType="card"
          personalAgreementLink={personalAgreementLink}
          regionId={regionId}
          newobjectsListingUrl={rentTrapUrl}
          bannerType={banner}
          defaultPhoneNumber={defaultPhoneNumber}
          gaLabel={gaLabel}
        />
      </NewbuildingKpnBannerContext.Provider>
    </div>
  );
};
